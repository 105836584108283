import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect,
} from "react-router-dom";
import "./App.scss";
import 'wowjs/css/libs/animate.css'
import 'animate.css';
import React, { Suspense, lazy, } from "react";
// import Frame from "./components/frame";
import {
  ErrorPage,
} from "./pages";
import isMobile from 'is-mobile'
// import { useAppContext } from "./contexts/appcontext.js";
// import Circle from "./components/circle/circle.js";
import "./utils/loco.css";
// import RoadMapPage from "./pages/roadmap";
// import MouseLight from './components/MouseLight/index.js';
const Home = lazy(() => import('./pages/home.js'));
const HomeH5 = lazy(() => import('./pages/h5/home.js'));

function App() {
  // const [url, setUrl] = useState()

  // function checkForMobileBg() {
  //   const width = window.innerWidth;
  //   if (width > 768) {
  //     setMobileFalse();
  //   } else if (width < 768) setMobileTrue();
  // }
  // checkForMobileBg();
  // useEffect(() => {
  //   checkForMobileBg();
  //   window.addEventListener("resize", checkForMobileBg);
  //   return () => window.removeEventListener("resize", checkForMobileBg);
  // }, [isMobile]);
  // useLayoutEffect(() => {
  //   setUrl(window.location.pathname)
  // }, [])

  // const circleRefs = useRef([]);
  // circleRefs.current = [];
  // useEffect(() => {
  //   const onMove = ({ clientX, clientY }) => {
  //     circleRefs.current.forEach((ref) => ref.moveTo(clientX, clientY));
  //   };
  //   window.addEventListener("pointermove", onMove);
  //   return () => window.removeEventListener("pointermove", onMove);
  // }, []);

  return (
    <Router>
      {/* {url === "/links" ? null : <Frame />} */}
      {/* {url === "/links" ? null : } */}
      {/* <MouseLight /> */}
      {/* <ScrollToTop /> */}
      {/* {!isMobile ? <Circle size="sm" delay="0" /> : null} */}
      <Switch>
        <Suspense fallback={<div></div>}>
          <Route path="/" exact>
            {isMobile() ? <HomeH5 /> : <Home />}
          </Route>
          <Redirect from='*' to='/' />
        </Suspense>
        <Route path="*">
          <ErrorPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default withRouter(App);