import React from 'react'
import { Link } from 'react-router-dom'
import './footer.scss'
import icQQ from '../assets/v2/ic_qq.png'
import icCircle from '../assets/v2/ic_circle.png'
import icWechat from '../assets/v2/ic_wechat.png'
import qrWechat from '../assets/v2/pic_qr_wechat.png'
import icWeibo from '../assets/v2/ic_weibo.png'
import { useAppContext } from '../contexts/appcontext.js'
import { showToast } from '../utils/util'

// gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

const Footer = ({ prj }) => {
  // const [email, setEmail] = useState('')
  const { changePointer } = useAppContext()
  const onWechatMouseOut = () => {
    document.getElementById('qr-wechat').style.display = 'none'
  }
  const onWechatMouseOver = () => {
    // document.getElementById('qr-wechat').style.display = 'block'
  }
  return (
    <div>
      <section className="sec-form footer-sec fot" id="footer">
        {/* <div className="flex flex-col items-center justify-center" style={{ height: '70vh' }}>
          <div className="text-white" style={{ fontSize: '42px', fontWeight: '700' }}>
            Get Newsletter
          </div>
          <div className="email-input mt-12 relative">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className=""
              placeholder="Your email"
            />
            <div
              className="absolute right-3 px-3 top-0 h-full flex items-center justify-center cursor-pointer"
              onClick={onGetNewsletter}
            >
              <img src={icArrowRight} alt="" style={{ height: '24px' }} />
            </div>
          </div>
        </div> */}
        <div className="w-full flex flex-col items-center mt-20">
          <div className="footer-foot">
            <div className="footer-secs l">
              <div className="menus">
                {/* <a href="https://mp.valuex.top">
                  <span className="cursor-pointer">创作中心</span>
                </a> */}
                {/* <Link to="#roadmap">
                  <span className="">Roadmap</span>
                </Link> */}
                {/* <div>
                  <a
                    href="#roadmap"
                    className="cursor-pointer">
                    Roadmap
                  </a>
                </div> */}
                {/* <Link to="#">
                  <span className="">关于我们</span>
                </Link> */}
              </div>
            </div>
            <div className="footer-secs r">
              <div className="relative">
                {[
                  {
                    icon: icWechat,
                    to: '#'
                  },
                  {
                    icon: icQQ,
                    to: '#'
                  },
                  {
                    icon: icCircle,
                    to: '#'
                  },
                  {
                    icon: icWeibo,
                    to: '#'
                  }
                ].map((item, index) => {
                  return (
                    <>{index === 0 && <div id="qr-wechat" style={{ display: 'none', position: 'absolute', top: '-130px', left: '-70px' }}>
                      <img src={qrWechat} width="120" /></div>}
                      <a className="icon-link" key={index} target="_blank" rel="noreferrer" onMouseOver={index == 0 ? onWechatMouseOver : null} onMouseOut={index == 0 ? onWechatMouseOut : null}>
                        <img className="icon" src={item.icon} alt="" />
                      </a>
                    </>
                  )
                })}
              </div>
              <Link
                to="#"
                className="pp"
                onMouseEnter={() =>
                  changePointer({
                    isHover: true,
                    color: { bg: '#fff' },
                    text: '',
                    blend: true,
                    sesize: '1.25'
                  })
                }
                onMouseLeave={() => changePointer({ isHover: false })}
              >
                {/* <p>商务电话：183-7468-2179</p> */}
              </Link>
            </div>
          </div>
          <div className="my-4" style={{ border: '0.7px solid #221E57', background: '#221E57', opacity: 0.3, width: '88%' }}></div>
          <div style={{ color: '#080B2799', fontSize: '12px' }}>
            <a href="https://beian.miit.gov.cn/" target="_blank" style={{ color: '#080B2799' }}>
              京ICP备2022022222号-2
            </a>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502053780" target="_blank" style={{ marginLeft: '26px' }}>京公网安备11010502053780号</a>
          </div>
        </div>
      </section>
    </div>
  )
}
export default Footer
