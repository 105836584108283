import React, { useEffect, useRef } from 'react'
import './showcase.scss'
import ImageCompare from '../ImageCompare'
// import ShowCaseM from '../ShowCaseMobile/ShowCaseM'
import picOZ from '../../assets/v2/pic_vox.png'
import textOZ from '../../assets/v2/text_ozink.png'
import icBlockchain from '../../assets/v2/ic_blockchain.png'
import icAI from '../../assets/v2/ic_ai.png'
import icCustom from '../../assets/v2/ic_custom.png'
// import textMore from '../../assets/v2/text_more.png'
import textLove from '../../assets/v2/text_love.png'
import picLove from '../../assets/v2/pic_love.png'
import icCreative from '../../assets/v2/ic_creative.png'
import icCollege from '../../assets/v2/ic_college.png'
import icCommunity from '../../assets/v2/ic_community.png'
import textCopyright from '../../assets/v2/text_copyright.png'
import picCopyright from '../../assets/v2/pic_copyright.png'
import icThreshold from '../../assets/v2/ic_threshold.png'
import icVerify from '../../assets/v2/ic_verify.png'
import icMonitor from '../../assets/v2/ic_monitor.png'
import icAuthorizat from '../../assets/v2/ic_authorizat.png'
import textAI from '../../assets/v2/text_ai.png'
import picAI1 from '../../assets/v2/pic_ai_1.png'
import picAI2 from '../../assets/v2/pic_ai_2.png'
import picAI3 from '../../assets/v2/pic_ai_3.png'
import picAI4 from '../../assets/v2/pic_ai_4.png'
import picAI5 from '../../assets/v2/pic_ai_5.png'
import picAI6 from '../../assets/v2/pic_ai_6.png'
import icDeduce from '../../assets/v2/ic_deduce.png'
import icDigital from '../../assets/v2/ic_digital.png'
import textIP from '../../assets/v2/text_ip.png'
import picIP from '../../assets/v2/pic_ip.png'
import site1 from '../../assets/v2/site_1.png'
import site2 from '../../assets/v2/site_2.png'
import textIncome from '../../assets/v2/text_income.png'
import picIncome from '../../assets/v2/pic_income.png'
import picRoadmap from '../../assets/v2/pic_roadmap.png'
import textParter from '../../assets/v2/text_partner.png'
import part1 from '../../assets/v2/part_1.png'
import part2 from '../../assets/v2/part_2.png'
import part3 from '../../assets/v2/part_3.png'
import part4 from '../../assets/v2/part_4.png'
import part5 from '../../assets/v2/part_5.png'
import part6 from '../../assets/v2/part_6.png'
import part7 from '../../assets/v2/part_7.png'
import cover1 from '../../assets/cover/a.png'
import cover2 from '../../assets/cover/b.png'
import partnersImg from '../../assets/images/partners.png'

// import MouseLight from '../../components/MouseLight/index.js';
const Showcase = () => {
  // const { isMobile } = useAppContext()
  return (
    <section
      style={{ paddingTop: '60px' }}
      id="content-box"
      className="fezhCh flex flex-col items-center justify-center"
    // data-scroll
    // id={sci}
    // ref={el}
    >
      {/* <MouseLight /> */}
      {/* {isMobile && <ShowCaseM showcasedata={showcasedata} />} */}
      {(
        <>
          {/** 0. ValueX */}
          <div className="item" id="item-first">
            <div className="shape pointer-events-none"></div>
            <div className="flex items-center">
              <div className="w-1/2">
                <img src={picOZ} alt="VOX" className="wow animate__animated animate__fadeInLeft" />
              </div>
              <div className="flex flex-col pl-12 w-1/2">
                <div>
                  <img src={textOZ} alt="VOX" />
                </div>
                <span className="text-white text-left mt-8 wow animate__fadeIn" style={{ fontSize: '16px' }}>
                ValueX 是一家原创快时尚在线零售商，汇聚了来自全世界优秀艺术家，向全球消费者提供丰富的个性化原创潮流产品，不断助力优质设计师及创作者的国际化发展，以用户主权数据版权保护为基础，持续的创意价值创造为发展理念，构筑全球数字潮流创意时尚生态链；旨在为Z世代居民（95-00后）满足他们对于开放性、趣味性和深度内涵的精神消费需求，促进精神与物质消费相互交融的新消费形态，助力产业数字化深度融合，开创新消费体验。
                </span>
                <div className="flex justify-between mt-2">
                  {[
                    { icon: icBlockchain, text: '区块链存证' },
                    { icon: icAI, text: 'AI模特演绎' },
                    { icon: icCustom, text: '按需定制' }
                  ].map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`flex flex-col items-center justify-center p-4 rounded ${index !== item.length - 1 ? 'mr-3' : ''
                          }`}
                      >
                        <img src={item.icon} width="80" />
                        <div className="mt-2 text-white" style={{ fontSize: '14px' }}>
                          {item.text}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/* <div style={{ textAlign: 'left' }}>
                  <a href="javascript:;" className="action-btn">
                    <span>了解更多</span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="h-48">
          </div>
          <ImageCompare first={cover1} second={cover2} />
          {/** 1. 表达主张 */}
          <div className="item" id="item-second">
            <div className="shape right"></div>
            <img src={textLove} alt="表达主张" width="200" />
            <div className="item-lg-title mt-4">Express Love</div>
            <div className="flex items-center mt-10">
              <div className="w-2/5 pt-10">
                <div className="text-left wow animate__fadeIn" style={{ fontSize: '16px' }}>
                  灵感来源于艺术家最核心的创造源泉，不论是感官交互还是思维碰撞，我们都希望所有艺术家在创作中表达自己最真实的主张
                </div>
                <div className="flex justify-between mt-4">
                  {[
                    { icon: icCreative, text: '创作主题活动' },
                    { icon: icCollege, text: 'ValueX创意学院' },
                    { icon: icCommunity, text: '艺术共创社区' }
                  ].map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`flex flex-col items-center justify-center p-2 rounded ${index !== item.length - 1 ? 'mr-3' : ''
                          }`}
                      >
                        <img src={item.icon} width="66" />
                        <div className="mt-2 text-white" style={{ fontSize: '14px' }}>
                          {item.text}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="w-3/5 pl-12">
                <img src={picLove} alt="" className="wow animate__animated animate__fadeInRight" />
              </div>
            </div>
          </div>
          {/** 2. 版权存证*/}
          <div className="item" id="item-thirdly">
            <div className="shape"></div>
            <img src={textCopyright} alt="版权存证" width="200" />
            <div className="item-lg-title mt-4">Copyright Protection</div>
            <div className="mt-4 item-desc">
              确权为艺术家的创作作品保驾护航，我们提供链上存证，确保版权为艺术家所有
            </div>
            <div className="flex items-center mt-10">
              <div className="wow animate__animated animate__fadeInLeft" >
                <img src={picCopyright} alt="版权存证" style={{ width: '600px' }} />
              </div>
              <div className="flex flex-wrap mt-2 pl-12 wow animate__animated animate__fadeInRight">
                {[
                  {
                    icon: icThreshold,
                    title: '低门槛',
                    desc: '高效算法识别审核\n司法可信电子存证证书'
                  },
                  {
                    icon: icVerify,
                    title: '验证易',
                    desc: '证明“创作在先”不再难\n随时随地验证真实性'
                  },
                  { icon: icMonitor, title: '监测全', desc: '实时追踪侵权\n全网检测追溯' },
                  { icon: icAuthorizat, title: '授权快', desc: '轻松完成多类型授权\n保证收入流向' }
                ].map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex flex-col text-white items-center justify-center rounded m-4 ${index > 1 ? 'mb-6' : 'mb-2'
                        }`}
                      style={{
                        background: 'rgb(255, 255, 255,0.02)',
                        borderRadius: '16px',
                        width: '240px',
                        padding: '44px 0 33px 0'
                      }}
                    >
                      <img src={item.icon} width="47.25" />
                      <div
                        className="mt-2 text-white"
                        style={{ fontSize: '18px', fontWeight: '500' }}
                      >
                        {item.title}
                      </div>
                      <div
                        className="mt-6 text-white whitespace-pre"
                        style={{ fontSize: '13px', fontWeight: '400' }}
                      >
                        {item.desc}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          {/** 3. AI模特渲染 */}
          <div className="item" id="item-fourthly" style={{ marginBottom: "2.8rem" }}>
            <div className="shape right"></div>
            <img src={textAI} alt="AI模特渲染" width="226" />
            <div className="item-lg-title mt-4">AI Generated Models</div>
            <div className="mt-4 item-desc">
              独创的AI model技术，将艺术渲染到日常生活，完美呈现灵动的演绎效果
            </div>
            <div className="flex items-center mt-10">
              <div className="flex flex-col  wow animate__animated animate__fadeInLeft">
                <img src={picAI1} alt="" style={{ height: '208px' }} />
                <div className="flex mt-1">
                  {[
                    {
                      icon: icDigital,
                      bg: picAI5,
                      title: '与数字作品完美结合',
                      desc: '差异化风格作品对应有不同的模特进行演\n绎，真正的所见即所得'
                    },
                    {
                      icon: icDeduce,
                      bg: picAI6,
                      title: '消费者定制化演绎',
                      desc: '智能推荐引擎，根据消费者偏好定制化演\n绎，真正的用户心头好'
                    }
                  ].map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`flex flex-col items-center justify-center py-8 px-4 ${index > 0 ? 'ml-1' : ''
                          }`}
                        style={{ backgroundImage: 'url(' + item.bg + ')', backgroundRepeat: 'no-repeat', backgroundSize: "280px" }}
                      >
                        <img src={item.icon} width="66" />
                        <div
                          className="mt-2 text-white"
                          style={{ fontSize: '16px', fontWeight: '500' }}
                        >
                          {item.title}
                        </div>
                        <div
                          className="mt-2 text-white whitespace-pre"
                          style={{ fontSize: '13px', fontWeight: '400' }}
                        >
                          {item.desc}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="flex ml-1  wow animate__animated animate__fadeInRight">
                <img src={picAI2} alt="" style={{ height: '420px' }} />
                <div className="flex flex-col ml-1">
                  <img src={picAI3} alt="" style={{ height: '208px' }} />
                  <img src={picAI4} alt="" className="mt-1" style={{ height: '208px' }} />
                </div>
              </div>
            </div>
          </div>
          {/** 4. IP赋能 */}
          <div className="item" id="item-fifth">
            <div className="shape"></div>
            <img src={textIP} alt="IP赋能" width="200" />
            <div className="item-lg-title mt-4">IP Empowerment</div>
            <div className="flex  items-center mt-10">
              <div className="w-1/2">
                <img src={picIP} alt="" className=" wow animate__animated animate__fadeInLeft" />
              </div>
              <div className="w-1/2 pl-12">
                <div className="wow animate__animated animate__fadeIn" style={{ textAlign: 'left', paddingRight: '20px', fontSize: '16px' }}>
                  ValueX提供全站式一体化孵化方案，包括运营作品的宣发，艺术家自媒体孵化，以及联合品牌方做联名品项等，赋能艺术家
                </div>
                <div className="mt-20">
                  {[
                    {
                      title: '顶尖IP引入 开放二创',
                      items: [
                        {
                          image: site1,
                          to: 'http://valuex.top'
                        },
                        {
                          image: site2,
                          to: 'http://valuex.top'
                        }
                      ]
                    },
                    {
                      title: '创作者IP孵化',
                      items: [
                        { title: '深度专访服务', to: 'http://valuex.top' },
                        { title: '全矩阵媒体宣发', to: 'http://valuex.top' }
                      ]
                    }
                  ].map((item, index) => {
                    return (
                      <div key={index} className={`flex flex-col py-2 mt-6`}>
                        <div className="font-bold text-left" style={{ fontSize: '15px' }}>
                          {item.title}
                        </div>
                        <div className="flex mt-6">
                          {item.items.map((subitem, subindex) => {
                            return (
                              <div key={subindex}>
                                {subitem.title && (
                                  <div
                                    className="bg-white mr-2 py-2 px-6 rounded"
                                    style={{
                                      fontSize: '14px',
                                      background: 'rgba(255, 255, 255, 0.05)'
                                    }}
                                  >
                                    {subitem.title}
                                  </div>
                                )}
                                {subitem.image && (
                                  <img src={subitem.image} style={{ width: '148px', marginRight: '12px' }} />
                                )}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          {/** 5. 可持续收益 */}
          <div className="item" id="item-sixth">
            <div className="shape right"></div>
            <img src={textIncome} alt="可持续收益" width="228" />
            <div className="item-lg-title mt-4">Income & Increase</div>
            <div className="flex  items-center mt-10">
              <div className="w-2/5 pt-10 wow animate__animated animate__fadeIn">
                <div className="text-left" style={{ fontSize: '16px' }}>
                  衍生品版权100%收益分配给艺术创作者，享受“睡后收入”的愉悦感受
                </div>
                <div className="flex flex-col mt-4">
                  {[
                    { text: '·版权交易收益，真正为艺术家赋予商业价值' },
                    { text: '·实体衍生潮品售卖：每售出一件，艺术家获得100%版权收益' },
                    { text: '·更多：跨界品牌联名、作品二创复利、灵感激励回馈' }
                  ].map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`rounded my-2 text-left`}
                        style={{
                          fontSize: '13px',
                          background: 'rgba(255, 255, 255, 0.05)',
                          padding: '10px 12px'
                        }}
                      >
                        {item.text}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="w-3/5">
                <img src={picIncome} alt="" className="wow animate__animated animate__fadeInRight" />
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col items-center w-full justify-center wow animate__animated animate__fadeIn" id="roadmap" style={{ marginTop: '180px' }}>
            <div className="shape"></div>
            <img src={picRoadmap} alt="Roadmap" width="1200px" />
          </div> */}
          <div className="item" id="item-eighth" style={{ borderRadius: '50', maxWidth: '1100px' }}>
            {/* style={{ height: '100vh'}} */}
            {/* <div className="shape right"></div> */}
            <div className="flex flex-col items-center justify-center py-16 wow animate__slideInDown">
              <img src={textParter} alt="合作伙伴" width="200" />
              <div
                className="font-bold mt-4 text-white"
                style={{ fontSize: '42px', fontWeight: '700' }}
              >
                Partners
              </div>
              {/* <div className="flex flex-wrap mt-6 items-center justify-center wow animate__fadeInUp">
                {[
                  { image: part1 },
                  { image: part2 },
                  { image: part3 },
                  { image: part6 },
                  { image: part7 }
                ].map((item, index) => {
                  return (
                    <img
                      src={item.image}
                      className="m-4"
                      style={{ width: '235px', height: '90px' }}
                      alt=""
                      key={index}
                    />
                  )
                })}
              </div> */}
              <div className="flex flex-wrap mt-6 items-center justify-center wow animate__fadeInUp">
                <img
                  src={partnersImg}
                  // className="m-4"
                  style={{ width: '800px',  }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  )
}
export default Showcase
