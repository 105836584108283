import React, { useEffect } from 'react'
// import Head from '../components/homeheader.js'
// import Loading from "./Loading.js"
import Footer from '../components/footer.js'
import Showcase from '../components/ShowCaseNew/index.js'
import Helmet from 'react-helmet'
import VideoPlay from '../components/VideoPlay/video'
// import MouseLight from '../components/MouseLight/index.js'
import SmoothScroll from 'smooth-scroll'
// const Loading = lazy(() => import('./Loading.js'))
import { WOW } from 'wowjs'

const HomePage = () => {
  useEffect(() => {
    new SmoothScroll('a[href*="#"]', {
      speed: 600,
      easing: 'easeInOutQuint'
    });
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 100,
      mobile: true,
      live: true
    })
    wow.init();
  })
  const updateContentBox = () => {
    document.getElementById('content-box').classList.add('dSPsJO')
    document.getElementById('content-box').classList.remove('fezhCh')
    document.getElementById('root').style.background = '#7B60F6'
  }
  const onContentBox = () => {
    document.getElementById('content-box').classList.add('fezhCh')
    document.getElementById('content-box').classList.remove('dSPsJO')
    document.getElementById('root').style.background = '#7B60F6' //黑色
  }
  const checkBottomCard = (e) => {
    // debugger
    // const height = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
    // console.log(height)
    // if (height > 768) {
    //   updateContentBox()
    // } else if (height < 768) onContentBox()
    //console.log(document.documentElement.scrollTop)
    if (document.documentElement.scrollTop > window.innerHeight + 20) {
      document.getElementById('topHeader').classList.add('topHeaderBg')
    } else {
      document.getElementById('topHeader').classList.remove('topHeaderBg')
    }
    if (document.documentElement.scrollTop < window.innerHeight/2) {
      document.getElementById('downloadView').classList.add('downloadViewTop')
    } else{
      document.getElementById('downloadView').classList.remove('downloadViewTop')
    }
    if (
      !!document.documentElement.scrollTop &&
      document.documentElement.scrollTop > 5500
    ) {
      // 页面高度大于200执行操作
      updateContentBox()
    } else {
      //  页面高度小于200执行操作
      onContentBox()
    }
  }
  useEffect(() => {
    checkBottomCard()
    window.addEventListener('scroll', checkBottomCard)
    return () => window.removeEventListener('scroll', checkBottomCard)
  }, [])

  return (
    <>
      <Helmet>
        <title>ValueX • 成就更多超级个体</title>
        <meta
          name="description"
          content="ValueX以数字艺术创作为主题，共创、共享、共治数字艺术潮品生态"
        />
      </Helmet>
      <div
        className="hue-can"
        style={{
          position: 'relative',
          width: '100vw',
          height: '10vh',
          background: '#f5f5f7',
          mixBlendMode: 'hue',
          opacity: '0',
          zIndex: 1
        }}
      ></div>
      {/* <Three /> */}
      {/* <AppScene /> */}
      <VideoPlay />
      {/* <div id="sti">
        <Head />
      </div> */}
      <Showcase />
      <Footer />
    </>
  )
}

export default HomePage
