import React from 'react'
// import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import './index.scss'
import Head from '../homeheader.js'
// import DownloadNew from '../DownloadNew/index.js'

// import videoUrl from "@/assets/oz/video/Header.mp4"
export default function VideoPlay(props) {
  // const { } = props
  // const videoRef = useRef(null)
  // const playerRef = useRef(null)
  // const [option, setOptopm] = useState({})

  // const onReadyPlay = (player) => {
  //   videoRef.current = player
  //   player.play()
  // }

  // const init = () => {
  //   let _option = {
  //     controls: false,
  //     autoplay: true, //加载完成是否自动播放
  //     loop: true, //视频播放结束后，是否循环播放
  //     notSupportedMessage: '此视频暂无法播放，请稍后再试',
  //     // poster: 'https://t7.baidu.com/it/u=1819248061,230866778&fm=193&f=GIF',//视频封面
  //     controlBar: {
  //       timeDivider: false, //是否显示时间控制条，默认为true
  //       remainingTimeDisplay: false, //是否显示剩余时间，默认为true
  //       fullscreenToggle: false, // 全屏按钮
  //       children: [
  //         //自定义
  //         { name: 'playToggle' }, // 播放按钮
  //         {
  //           name: 'volumePanel', // 音量控制
  //           inline: false // 不使用水平方式
  //         },
  //         { name: 'currentTimeDisplay' }, // 当前已播放时间
  //         { name: 'durationDisplay' }, // 总时间
  //         { name: 'progressControl' }, // 播放进度条
  //         {
  //           name: 'pictureInPictureToggle' //支持画中画
  //         },
  //         {
  //           name: 'FullscreenToggle' //支持全屏
  //         }
  //       ]
  //     }
  //   }
  //   setOptopm(_option)

  //   if (!playerRef.current) {
  //     const videoElement = videoRef.current
  //     if (!videoElement) return

  //     const player = (playerRef.current = videojs(videoElement, _option, () => { }))
  //     onReadyPlay(player)
  //   }
  // }

  // useEffect(() => {
  //   // init()
  // }, [])

  return (
    <div className="video_play relative">
      {/* <div className="opacity-video"></div> */}
      <span className={`span-back`} data-bg='#ffffff'
        data-cur='#141416'></span>
      {/* <video
        src="//valuex.top/upload/video/200.mp4"
        // src={require(videoUrl).default}
        className='videoElement'
        autoPlay="autoplay"
        loop="loop"
        muted="muted"
        controlsList="nodownload"
        height="100%"
        width="100%"
      ></video> */}
      <img src="/image/activity240618.jpg" className="activity-img w-full pointer-events-auto cursor-pointer" onClick={()=>{
        window.open("https://mp.valuex.top/activity/activity240618")
      }}/>
      {/* <DownloadNew/> */}
      {/* <video style={{

        width: 500,
        height: 300
      }} ref={videoRef}
        className="video-js vjs-big-play-centered">
        <source src={`http://vjs.zencdn.net/v/oceans.mp4`} type="video/mp4" />
        <source src={`https://live-s3m.mediav.com/nativevideo/2a80d171cc7ef2c764c9a83c06e0e4bc-bit_cloud768.mp4`} type="video/mp4" height=""/>
      </video> */}
       <Head />
    </div>
  )
}
