import React from 'react'
import Logo from '../assets/v2/logo_h.png'
import { showToast } from '../utils/util'
function FrameHeader({ }) {
  return (
    <>
      <div className="frame-wrapper-h5" style={{ width: '100vw' }}>
        <div className={`frame f-top flex items-center justify-center`}>
          <img src={Logo} alt="VOX" width="63" />
        </div>
      </div>
    </>
  )
}

export default FrameHeader;
