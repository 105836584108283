import React, { useRef, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { links } from "../../utils/constans";
import Frame from "../../assets/v2/Frame.png";
import icNew from "../../assets/oz/ic_new.png";
import { useAppContext } from "../../contexts/appcontext.js";
import gsap from "gsap";
function Header({ history }) {
  const {
    ScrollYValue,
  } = useAppContext();
  //  animations setup
  const el = useRef();
  const tempSvg = useRef();
  const mainSvg = useRef();

  useEffect(() => {

    if (ScrollYValue === 0) {
      gsap.set(mainSvg.current, {
        opacity: 0,
        y: 20,
      });
    } else {
      gsap.set(tempSvg.current, {
        opacity: 0,
        y: -27,
      });
    }
  }, []);
  useEffect(() => {
    gsap.to(tempSvg.current, {
      autoAlpha: () => (ScrollYValue === 10 ? 0 : 1),
      y: () => (ScrollYValue === 10 ? -20 : 0),
      duration: 0.25,
      ease: "Power3.In",
    });
    gsap.to(mainSvg.current, {
      autoAlpha: () => (ScrollYValue === 10 ? 1 : 0),
      y: () => (ScrollYValue === 10 ? 0 : 27),
      duration: 0.25,
      ease: "Power3.In",
    });
  }, [ScrollYValue]);
  return (
    <>
      <div
        id="topHeader"
        className={`frame f-top flex items-center w-full justify-center pt-2`}
      >
        <div className="flex items-center justify-between w-4/5 pt-4 pb-2">
          <Link
            to="/"
            className={`logo pointer-events-auto`}
          >
            <img className="sm-logo" ref={tempSvg} src={Frame} alt="VOX" width="68" height="24" />
            <img ref={mainSvg} src={Frame} alt="VOX" width="68" height="24" />
          </Link>
          <div className="navlinks-contain">
            {links.map((link) => {
              const { id, text, url, ariaLabel } = link;
              return (
                <li
                  key={id}
                  className="relative pointer-events-auto"
                >
                  {id==='na1Li0' && <div className="absolute z-20 right-0" style={{top:'-8px'}}> 
                    <img src={icNew} width="28" />
                  </div>}
                  <a href={url} target="_blank" rel="noreferrer">
                    <span>{text}</span>
                  </a>
                </li>
              );
            })}
            {/* {isMobile && isMenuOpen && (
              <li className="mobile-theme-swithcer">
                <ThemeSwitch />
              </li>
            )} */}
            {/* <li
              className={`menu ${isMenuOpen ? "active" : ""}`}
              onClick={toggleMenu}
            >

              <div className="menu-trigger" onClick={toggleMenu}></div>

              <svg
                className="menuLines l1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 27 3"
              >
                <rect
                  id="Rectangle_298"
                  data-name="Rectangle 298"
                  width="25"
                  height="2.5"
                  rx="1.5"
                  fill="#1a1a1a"
                />
              </svg>

              <svg
                className="menuLines l2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 27 3"
              >
                <rect
                  id="Rectangle_298"
                  data-name="Rectangle 298"
                  width="25"
                  height="2.5"
                  rx="1.5"
                  fill="#1a1a1a"
                />
              </svg>
            </li> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Header);
