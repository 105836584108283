import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { useAppContext } from '../contexts/appcontext.js'
import './canvas.css'
import './homeheader.scss'
import Frame from '../components/frame'
import FrameHeader from '../components/FrameHeader'
import Download from './Download/'
import isMobile from 'is-mobile'
const Head = () => {
  // const el = useRef()

  return (
    <div className="pointer-events-none">
      <section className="head" style={{ position: 'absolute', top: 0, left: 0 }}>
        {isMobile() ? <FrameHeader /> : <Frame />}
        {/* {isMobile() ? <h1>111</h1> : <h1>222</h1>} */}
        <div className="backg">
          <div className="darkLay"></div>
          <div className="darkLay2"></div>
        </div>
        {isMobile() ? (
          <div className="h1 mobile-home-head">
            <h1 style={{ position: isMobile ? 'static' : '' }}>
              {/* <span className="headSpan">
                <span>Innovation</span>
              </span>
              <span className="headSpan">
                <span>is</span>
              </span>
              <span className="headSpan">
                <span>anywhere</span>
              </span> */}
              {/* <div className="call-action">
                <Link to="/contact" aria-label="estimate-project" className="estimate-btn btn">
                  创作中心
                </Link>
              </div> */}
            </h1>
          </div>
        ) : (
          <>
            <Download />
            {/* <div className="h1 ph-bold">
              <h1
                className="select-none"
              > */}
                {/* <span className="headSpan ph-bold">
                  <span>Join us, You'll create a diverse world</span>
                </span> */}
                {/* <span className="headSpan head-ml ph-bold">
                  <span>is</span>
                </span>
                <span className="headSpan head-ml ph-bold">
                  <span >Anywhere</span>
                </span> */}
                {/* <div className="headSpan ph-bold" style={{ fontSize: '36px', opacity: '0.85' }}>
                成就更多超级个体
                </div> */}
              {/* </h1>
            </div> */}
          </>
        )}
        {/* <Scene/> */}
        {/* <div className="h2-wrapper">
          <div className="h2-con">
            <div className="h2 home">
              <h6>ValueX</h6>
              {isMobile ? (
                <h3>ValueX以数字艺术创作为主题，共创、共享、共治数字艺术潮品生态</h3>
              ) : (
                <h3> <div>基于区块链技术的数字艺术版权和衍生潮品一站式服务共创web3.0平台；</div>
                  <span>旨在为艺术家、收藏家和市场创造全新的数字艺术价值，提供灵感获取、IP孵化、</span>
                  <div>确权保护、版权流转、AI模特演绎、衍生定制等一整套解决方案。</div>
                </h3>
              )}
              <h3 className="flex justify-between mt-4 pl-56 pr-56">
                {[{ icon: icon1, text: '区块链存证' }, { icon: icon1, text: 'AI模特演绎' }, { icon: icon1, text: '按需定制' }].map((item, index) => {
                  return <div className={`bg-white flex flex-col items-center justify-center p-4 rounded-md ${index != item.length - 1 ? "mr-3" : ""}`} key={item.text}>
                    <img src={item.icon} />
                    <div className="mt-2" style={{ fontSize: "13px", color: "black" }}>{item.text}</div>
                  </div>
                })}
              </h3>
            </div>
            {isMobile ? (
              <MButton url="/about" text="了解更多" />
            ) : (
              <Button ss=".3" did="btnreveal" text="了解更多" url="/about" />
            )}
          </div>
        </div> */}
      </section>
    </div>
  )
}
export default Head
