import { ImgComparisonSlider } from '@img-comparison-slider/react';
import './index.scss';
import SliderImage from "../../assets/images/svg/slider.svg";
const ImageCompare = (props) => {
    const { first, second } = props

    return <div className='image-compare'>
        <ImgComparisonSlider>
            <div slot="first" >
                <img src={first} alt='' className='img1' />
                <div className="before-text">Before</div>
            </div>
            <div slot="second" >
                <img src={second} alt='' className='img2' />
                <div className='after-text'>After</div>
            </div>
            <div slot='handle' className="handle-image">
                <img src={SliderImage} alt='' />
            </div>
        </ImgComparisonSlider>
    </div>
}

export default ImageCompare