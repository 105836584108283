import './index.scss'
import React, { useState } from 'react'
import iphoneImg from '../../assets/images/iphone.png'
import ozinkImg from '../../assets/images/valuex.png'
import appCoverImg from '../../assets/images/app_cover.png'
import icIos from '../../assets/images/ic_ios.png'
import icAndroid from '../../assets/images/ic_android.png'
import qrAndroid from '../../assets/images/qr_android.png'
import qrIos from '../../assets/images/qr_ios.png'
import { downloadUrlIOS, downloadUrlAndroid } from '../../utils/constans'

const Download = () => {
  const [showIOS, setShowIOS] = useState(false)
  const [showAndroid, setShowAndroid] = useState(false)

  return (
    <div className="download" id="downloadView">
      <div className="middle">
        <div className="middle-wrapper">
          <div className="iphone-wrapper">
            <div className="iphone pointer-events-auto">
              <div className="iphone-mp4">
                <video
                  poster={appCoverImg}
                  autoPlay="autoplay"
                  loop="loop"
                  muted="muted"
                  controlsList="nodownload"
                  className="my-video"
                  src="//valuex.top/upload/video/app_v3.mp4"
                  preload="auto"
                ></video>
              </div>
              <div
                className="iphone-case"
                style={{ backgroundImage: 'url(' + iphoneImg + ')' }}
              ></div>
            </div>
          </div>
          <div className="description">
            <div className="logo-big pointer-events-auto" style={{ backgroundImage: 'url(' + ozinkImg + ')' }}></div>
            <h2 className="lifestyle pointer-events-auto">成就更多超级个体</h2>
            <h5 className="lifestyle-english"></h5>
            <div className="qrcodes">
              <a
                className="ios pointer-events-auto"
                href={downloadUrlIOS}
                target='_blank'
                onMouseEnter={() => setShowIOS(true)}
                onMouseLeave={() => setShowIOS(false)}
              >
                <span className="ios-icon">
                  <img src={icIos} />
                </span>
                iOS 版
                {showIOS && (
                  <div className="qr-img">
                    <img src={qrIos} width="132" />
                  </div>
                )}
              </a>

              <a
                className="android ml-4 pointer-events-auto"
                href={downloadUrlAndroid}
                target='_blank'
                onMouseEnter={() => setShowAndroid(true)}
                onMouseLeave={() => setShowAndroid(false)}
              >
                <span className="android-icon">
                  <img src={icAndroid} />
                </span>
                Android 版
                {showAndroid && (
                  <div className="qr-img">
                    <img src={qrAndroid} width="132" />
                  </div>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Download
