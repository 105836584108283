import cover1 from '../assets/oz/home/1-1.png'
import cover2 from '../assets/oz/home/2.png'
import cover3 from '../assets/oz/home/3.png'
import cover4 from '../assets/oz/home/4.png'
import cover5 from '../assets/oz/home/5.png'

export const links = [
  {
    id: "na1Li1",
    text: "创作中心",
    url: "https://mp.valuex.top",
    ariaLabel: "创作中心",
  }
  // {
  //   id: "na1Li1",
  //   text: "数字社区",
  //   url: "#item-second",
  //   ariaLabel: "community",
  // },
  // {
  //   id: "na1Li2",
  //   text: "Roadmap",
  //   url: "#roadmap",
  //   ariaLabel: "roadmap page",
  // },
  // {
  //   id: "na1Li3",
  //   text: "帮助中心",
  //   url: "#",
  //   ariaLabel: "help page",
  // },
  // {
  //   id: "na1Li2",
  //   text: "联系我们",
  //   url: "#",
  //   ariaLabel: "about page",
  // },
];


export const home = [
  {
    num: "Inspiration",
    id: "hp1",
    h4: "设计创作灵感\n聚集地",
    p: "",
    a: { text: "Learn more", url: "/" },
    img: {
      cover: cover1,
      colors: { cur: "#FF99CE", pointer: "#CC669Bbf", bg: "#FAE1EE", tx: "#E67FB4", br: "#FEA5D3" },
      klass: "first",
    },
  },
  {
    num: "Safety",
    id: "hp2",
    h4: "版权保护变得\n更简单",
    p: "",
    a: { text: "Learn more", url: "/" },
    img: {
      cover: cover2,
      colors: { cur: "#808CFF", pointer: "#808cffbf", bg: "#D3D6F0", tx: "#7781D9", br: "#A6AFFF" },
      klass: "",
    },
  },
  {
    num: "AI Rendering",
    id: "hp3",
    h4: "AI渲染展示\n衍生品",
    p: "",
    a: { text: "Learn more", url: "/" },
    img: {
      cover: cover3,
      colors: { cur: "#F2AB79", pointer: "#F2AB79bf", bg: "#FFEDE0", tx: "#F2AB79", br: "#F2AB79" },
      klass: "",
    },
  },
  {
    num: "Guarantee",
    id: "hp4",
    h4: "严格把控产品\n高保障",
    p: "",
    a: { text: "Learn more", url: "/" },
    img: {
      cover: cover4,
      colors: { cur: "#FFB18C", pointer: "#FFA073bf", bg: "#FFEAE0", tx: "#FFB18C", br: "#FFB18C" },
      klass: "threeD",
    },
  },

  {
    num: "Profit",
    id: "hp5",
    h4: "持续版权收益\n很可观",
    p: "",
    a: { text: "Learn more", url: "/" },
    img: {
      cover: cover5,
      colors: { cur: "#77A9D9", pointer: "#669bccbf", bg: "#E0F0FF", tx: "#78A9D9", br: "#A6D4FF" },
      klass: "end",
    },
  },
];

export const downloadUrlAndroid = 'https://m.valuex.top/upload/apk/app-latest.apk'
export const downloadUrlIOS = 'https://apps.apple.com/us/app/valuex/6472241469'